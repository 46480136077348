
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
//import { timeStamp } from 'console';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared-services/alert.service';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { ProfessionDataService } from 'src/app/shared-services/profession-data.service';
import { UserService } from 'src/app/shared-services/userservice.service';
import { threadId } from 'worker_threads';
import { CustomValidators, errorMessages, MyErrorStateMatcher } from 'src/app/authentication/registration/password-match';
import { ValidationService } from  'src/app/authentication/services/validation.service';
import { AdminService } from 'src/app/shared-services/adminservice.service';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrls: ['./edit-profile.component.scss']
  })
  export class EditProfileComponent implements OnInit {

   
public isNoteOpen:boolean;
public isEnterOtc:boolean;
public isMobileToken:boolean;
public isEmailToken:boolean
public isEmailEnterOtc:boolean
public isOptionsOpen:boolean;

public updateProfileForm:FormGroup;
public OTCForm:FormGroup;
public editPhoneForm:FormGroup;
public editEmailForm:FormGroup;
public professions:any;
public specialities:any;
public Email;
public isEmail:boolean;
public isMobile:boolean;
matcher = new MyErrorStateMatcher();
errors = errorMessages;
public fullName;
public userName;
public phoneNumber;
public updatePhoneNumber;
public professionId;
public buttonText = "Update";
public text;
public otcMessageEmail:boolean;
public otcMessageMobile:boolean;
public isCaregiver:boolean;
public isRecruiter:boolean;
public loginToken;
public specialityId;
public userId;
public isDisable:boolean = true;
public isloading:boolean;
public isPwdloading:boolean;
public isResendLoading:boolean;
public isEnableStar:boolean;
public isEnableStar1:boolean;
public toSelectSpecialty1=[];
options = {
  autoClose: true,
  keepAfterRouteChange: false
};
@ViewChild("redirectToLogin") redirectToLogin:TemplateRef<any>;
@ViewChild("reSentOtc") reSentOtc:TemplateRef<any>;
@ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
@ViewChild("selectNotipre") selectNotipre:TemplateRef<any>; 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder:FormBuilder,
    private validationService:ValidationService,
    private userService:UserService,
    private authenticationService:AuthenticationService,
    private adminService:AdminService,
    private dialog:MatDialog,
    private router: Router,
    private alertService:AlertService,
    private route: ActivatedRoute,
    private professionDataService:ProfessionDataService
  ) { 
    console.log(data);
    this.getProfession();
    this.UserProfileForm();
    this.adminService.editData.subscribe((res)=>{
        console.log(res);
        if(res.body.roleId == 1){
          this.isCaregiver = true;
          this.isRecruiter = false;
          this.updateProfileForm.get('profession').setValidators([Validators.required]);
          this.updateProfileForm.get('speciality').setValidators([Validators.required]);
          this.updateProfileForm.get('agencyName').setValidators([]);
        }
        else if(res.body.roleId == 2){
          this.isRecruiter = true;
          this.isCaregiver = false;
          this.updateProfileForm.get('agencyName').setValidators([Validators.required]);
          this.updateProfileForm.get('profession').setValidators([]);
          this.updateProfileForm.get('speciality').setValidators([]);
        }

   this.userName = res.body.email;
   this.phoneNumber = res.phoneNumber;
   this.professionId = res.body.professionId;
   this.specialityId = res.body.specialtyId;
   this.speciality();   
    this.updateProfileForm.controls['firstName'].setValue(res.body.firstName);
    this.updateProfileForm.controls['lastName'].setValue(res.body.lastName);
    this.updateProfileForm.controls['username'].setValue(res.body.email);
    this.updateProfileForm.controls['phoneNumber'].setValue(res.body.phoneNumber);
    this.updateProfileForm.controls['agencyName'].setValue(res.body.agencyName);
   
   
   this.userId = res.body.userId
     
    if(res.body.enableEmailNotif === "YES"){
      this.Email = 'YES';
      this.isEmail = true;
        this.updateProfileForm.controls['enableEmailNotif'].setValue(true);
    }
    else {
      this.isEmail = false;
    }
     if(res.body.enableMobileNotif === "YES"){
      this.isEnableStar1 =true;
      this.isMobile = true;
      this.isNoteOpen = true;
      this.updateProfileForm.controls['enableMobileNotif'].setValue(true);
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])


    }
    else {
      this.isEnableStar1 = false;
      this.isMobile = false;
      this.isNoteOpen = false;
      this.updateProfileForm.controls['enableMobileNotif'].setValue(false);
      this.updateProfileForm.get('phoneNumber').setValidators([Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
    }
    
    })
    //this.OTCForm = new FormGroup({
      //otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)]),
   // });
    this.editPhoneForm = new FormGroup({
    //phoneNumber:new FormControl('', [Validators.required, this.validationService.checkPhoneNumber]),
    otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)])
  });
  this.editEmailForm = new FormGroup({
    username:new FormControl('', [Validators.required, this.validationService.checkEmail]),
    otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)])
  });
  }

  ngOnInit(): void {

    this.phoneMandatory();
    this.checkBoxMandatory();
    window.scrollTo( 0, 0 );
    sessionStorage.setItem('isNextTab', 'yes');



  }
  radioButton(event){
  //  console.log(event);
  if(event.source.checked && event.source.value == "email"){
    this.isEmail = true;
  }
  else if(!event.source.checked && event.source.value == "email"){
    this.isEmail = false;
  }
   if(event.source.checked && event.source.value == "sms"){
    this.isMobile = true;
    this.isNoteOpen = true;

  }
  else if(!event.source.checked && event.source.value == "sms"){
    this.isMobile = false;
    this.isNoteOpen = false;

  }
    }
    UserProfileForm() {
      this.updateProfileForm = this.formBuilder.group({
       // role: 'CARE_GIVER',
        firstName: ['', [Validators.required, Validators.max(13)]],
        lastName: ['', [Validators.required, Validators.max(13)]],
        username: ['', [Validators.required, this.validationService.checkEmail]],
        otcUserName: ['',[Validators.pattern(this.validationService.otcregex)]],
        phoneNumber: ['', [this.validationService.checkPhoneNumber]],
        otcPhoneNumber: ['',[Validators.pattern(this.validationService.otcregex)]],
        agencyName:[''],
        profession:[''],
        speciality:[''],
        enableEmailNotif:['email', [Validators.required]],
        enableMobileNotif:['sms', [Validators.required]],
        'validate': ''
      });
      }
      checkBoxMandatory(){
          const phoneControl = this.updateProfileForm.get('enableMobileNotif');
        const emailControl = this.updateProfileForm.get('enableEmailNotif');
        this.updateProfileForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => {
          if(!value && !this.isEmail){
            this.updateProfileForm.get('enableEmailNotif').setValidators([Validators.required])
          }
          phoneControl.updateValueAndValidity();
        }
        )
        this.updateProfileForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => {
          if(!value && !this.isNoteOpen){
            this.updateProfileForm.get('enableEmailNotif').setValidators([Validators.required])
          }
          emailControl.updateValueAndValidity();
        })
        }
       

      

        phoneMandatory(){
            const phoneControl = this.updateProfileForm.get('phoneNumber');
          this.updateProfileForm.get('enableMobileNotif').valueChanges
            .subscribe(value => {
              this.isEnableStar1 = value;
              if(value) {
                this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
              } else {
                this.updateProfileForm.get('phoneNumber').setValidators([Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
              }
              phoneControl.updateValueAndValidity();
            }
        );
        // this.updateProfileForm.get('enableEmailNotif').valueChanges
        // .subscribe(value => {
        //   if(value) {
        //     this.updateProfileForm.get('phoneNumber').setValidators([ Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
        //   } else {
        //     this.updateProfileForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
        //   }
        //   phoneControl.updateValueAndValidity();
        // }
        // );
      }
        
       
     
      getProfession(){
          return this.userService.getProfessions().subscribe(professionsObj => {
                if (!!professionsObj) {
                  this.professions = professionsObj.professionLookups;
          this.professions.sort(function(a, b) {
            var nameA = a.professionName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.professionName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
                 
                }
                const toSelectProfession = this.professions.find(c => c.id == this.professionId);
                this.updateProfileForm.controls['profession'].setValue(toSelectProfession);
        });
      
      }
      populateSpecialties(event) {
        this.updateProfileForm.get('speciality').reset();
        this.userService.getSpecialties(event.value.id)
        .subscribe(specialties => {
          this.specialities = specialties;
          this.specialities.sort(function(a, b) {
            var nameA = a.specialtyName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.specialtyName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
        });
      }
      speciality(){
        this.userService.getSpecialties(this.professionId)
        .subscribe(specialties => {
          this.specialities = specialties;
          this.specialities.sort(function(a, b) {
            var nameA = a.specialtyName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.specialtyName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
          const toSelectSpecialty = this.specialities.find(c => c.id == this.specialityId);
    this.updateProfileForm.controls['speciality'].setValue(toSelectSpecialty);
        });
      }

      getErrorEmail() {
        return this.updateProfileForm.get('username').hasError('required') ? 'Email is required.' :
       // this.updateCaregiverForm.get('username').hasError('whitespace') ? 'noWhiteSpace' :
          this.updateProfileForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }

      getErrorPhone() {
        return this.updateProfileForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
          this.updateProfileForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }




onProfileUpdate(post){ /** update profile details */
  this.isloading = true;
  const inputObj:any={};
  if(post.enableEmailNotif == true){
    inputObj.enableEmailNotif = "YES"
  }
  else{
    inputObj.enableEmailNotif = "NO"
  }
  if(post.enableMobileNotif == true){
    inputObj.enableMobileNotif = "YES"
 }
 else{
  inputObj.enableMobileNotif = "NO"
 }   
 if(inputObj.enableEmailNotif !== "YES" && inputObj.enableMobileNotif !== "YES"){
  this.dialog.open(this.selectNotipre);
  this.isloading = false;
} else if (this.updateProfileForm.valid && (inputObj.enableEmailNotif == "YES" || inputObj.enableMobileNotif == "YES")){
 
   inputObj.fullName = post.fullName;
   if(this.isCaregiver){
   inputObj.profession = post.profession.id;
   inputObj.speciality = post.speciality.id;
  }
  else if(this.isRecruiter){
    inputObj.agencyName = post.agencyName.trim();
  }
   inputObj.phoneNumber = post.phoneNumber.trim();
   this.updatePhoneNumber = post.phoneNumber.trim();
   inputObj.username = post.username.trim();
  // var fullname = post.fullName.split(' ');
   inputObj.firstName = post.firstName.trim();
   inputObj.lastName = post.lastName.trim();
   //inputObj.userToken = this.loginToken;
   inputObj.userId = this.userId;
  this.adminService.updateUserProfileByAdmin(inputObj).subscribe((res:any)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isloading = false;
    if(res.responseCode == 200 && res.responseMessage == null ){
      this.dialog.closeAll();
      this.alertService.success('Success! User with '+this.userId  +'details have been updated', this.options);
    // sessionStorage.setItem("updateSuccessMessage", "Yes");
    // this.authenticationService.raiseProfileEvent();
     
    }
    
  
  })
  } 
}
closeAll(){
  //this.formDirective.resetForm();
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
this.isEmailToken =false;
this.isMobileToken = false;
this.dialog.closeAll();
}
  }
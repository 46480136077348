import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,HttpRequest, HttpResponse} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./shared-services/authentication.service";
import { UserService } from "./shared-services/userservice.service";
 
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private userService : UserService
    ) {
    }
    private handleError(error:HttpErrorResponse) {
        //console.log("This is getting error:")
       // console.log(error);
        return throwError(error);
  }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      //  console.log(req);
        return next.handle(req)
        .pipe(map(resp=>{
            if (resp instanceof HttpResponse) {
              //  console.log(resp);
              //  console.log(resp.body);
                return resp;
            }
        }),
        catchError(err=>  {
        //    console.log(err);
            if (err.status === 412 ) {
              //alert('Account should be validate, please validate your account by providing validation code');
                this.authenticationService.logout();
                this.userService.logout();
                this.authenticationService.loginStatus.next(false);
                   this.authenticationService.caregiverLogin.next(false);
                   this.authenticationService.recruiterLogin.next(false);
                this.router.navigateByUrl('/login');
                this.router.navigate(['/login'], { relativeTo:this.route});
          }
          else if(err.status === 401 && err.error.error == "invalid_token"){
            alert('session was expired, please login again to continue');
            localStorage.removeItem('remember');
              localStorage.removeItem('Authority');
            localStorage.removeItem('jwt');
            localStorage.setItem("loginStatus", '0');
            this.router.navigateByUrl('');
            this.router.navigate([''], { relativeTo:this.route});
            this.authenticationService.loginStatus.next(false);
                   this.authenticationService.caregiverLogin.next(false);
                   this.authenticationService.recruiterLogin.next(false);
          }
          else if(err.status === 401 && localStorage.getItem('remember') === "Yes" && err.error.error_description.startsWith("Access token expired") && (err.error != "unauthorized")){
              alert('session was expired, please login again to continue');
              localStorage.removeItem('remember');
            localStorage.removeItem('jwt');
            localStorage.setItem("loginStatus", '0');
            this.router.navigateByUrl('/login');
            this.router.navigate(['/login'], { relativeTo:this.route});
            this.authenticationService.loginStatus.next(false);
                   this.authenticationService.caregiverLogin.next(false);
                   this.authenticationService.recruiterLogin.next(false);
          }else if(err.status === 401 && localStorage.getItem('remember') === "Yes" && (err.error.error == "unauthorized")){
           // alert('Full authentication required, please login again to continue');
            localStorage.removeItem('remember');
          localStorage.removeItem('jwt');
          localStorage.setItem("loginStatus", '0');
          this.router.navigateByUrl('/login');
          this.router.navigate(['/login'], { relativeTo:this.route});
          this.authenticationService.loginStatus.next(false);
                 this.authenticationService.caregiverLogin.next(false);
                 this.authenticationService.recruiterLogin.next(false);
        }
          else if(err.status === 401 && (err.error.error == "unauthorized") && (localStorage.getItem('remember') === "No" ||  localStorage.getItem('remember') === null)){
            //alert('Full authentication required, please login again to continue');
            localStorage.removeItem('remember');
              localStorage.removeItem('Authority');
            localStorage.removeItem('jwt');
            localStorage.setItem("loginStatus", '0');
            this.router.navigateByUrl('');
            this.router.navigate([''], { relativeTo:this.route});
            this.authenticationService.loginStatus.next(false);
                   this.authenticationService.caregiverLogin.next(false);
                   this.authenticationService.recruiterLogin.next(false);
          }else if(err.status === 401 && (err.error.error != "unauthorized") && err.error.error_description.startsWith("Access token expired") && (localStorage.getItem('remember') === "No" ||  localStorage.getItem('remember') === null)){
            alert('session was expired, please login again to continue');
            localStorage.removeItem('remember');
              localStorage.removeItem('Authority');
            localStorage.removeItem('jwt');
            localStorage.setItem("loginStatus", '0');
            this.router.navigateByUrl('');
            this.router.navigate([''], { relativeTo:this.route});
            this.authenticationService.loginStatus.next(false);
                   this.authenticationService.caregiverLogin.next(false);
                   this.authenticationService.recruiterLogin.next(false);
          } 
          return throwError(err);
        }
        )
        )
    }
}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
//import { timeStamp } from 'console';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { AlertService } from 'src/app/shared-services/alert.service';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';
import { ProfessionDataService } from 'src/app/shared-services/profession-data.service';
import { UserService } from 'src/app/shared-services/userservice.service';
import { threadId } from 'worker_threads';
import { CustomValidators, errorMessages, MyErrorStateMatcher } from '../registration/password-match';
import { ValidationService } from '../services/validation.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

public isProfilOpen:boolean;
public isChangePwdOpen:boolean;
public isNoteOpen:boolean;
public isEnterOtc:boolean;
public isMobileToken:boolean;
public isEmailToken:boolean
public isEmailEnterOtc:boolean
public isOptionsOpen:boolean;
public updateCaregiverForm:FormGroup;
public updateRecruiterForm:FormGroup;
public updateAdminForm:FormGroup;
public updatePassword:FormGroup;
public OTCForm:FormGroup;
public editPhoneForm:FormGroup;
public editEmailForm:FormGroup;
public professions:any;
public specialities:any;
public Email;
public isSelectImage:boolean = false;
public isEmail:boolean;
public isMobile:boolean;
matcher = new MyErrorStateMatcher();
errors = errorMessages;
public userImage;
public fullName;
public userName;
public phoneNumber;
public updatePhoneNumber;
public professionId;
public buttonText = "Update";
public text;
public otcMessageEmail:boolean;
public otcMessageMobile:boolean;
public isCaregiver:boolean;
public isRecruiter:boolean;
public isAdmin:boolean;
public loginToken;
public specialityId;
public userId;
public isDisable:boolean = true;
public isloading:boolean;
public isPwdloading:boolean;
public isResendLoading:boolean;
public isEnableStar:boolean;
public isEnableStar1:boolean;
options = {
  autoClose: true,
  keepAfterRouteChange: false
};
@ViewChild("imageView") imageView:TemplateRef<any>;
@ViewChild("redirectToLogin") redirectToLogin:TemplateRef<any>;
@ViewChild("reSentOtc") reSentOtc:TemplateRef<any>;
@ViewChild(FormGroupDirective) formDirective: FormGroupDirective;
@ViewChild("selectNotipre") selectNotipre:TemplateRef<any>; 
  constructor(
    private formBuilder:FormBuilder,
    private validationService:ValidationService,
    private userService:UserService,
    private authenticationService:AuthenticationService,
    private dialog:MatDialog,
    private router: Router,
    private alertService:AlertService,
    private route: ActivatedRoute,
    private professionDataService:ProfessionDataService
  ) { 
    
    //this.OTCForm = new FormGroup({
      //otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)]),
   // });
    this.editPhoneForm = new FormGroup({
    //phoneNumber:new FormControl('', [Validators.required, this.validationService.checkPhoneNumber]),
    otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)])
  });
  this.editEmailForm = new FormGroup({
    username:new FormControl('', [Validators.required, this.validationService.checkEmail]),
    otc: new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)])
  });
  }

  ngOnInit(): void {
    this.userService.goToProfileScreen.subscribe(()=>{
      this.openProfile();
    })
    window.scrollTo( 0, 0 );
    sessionStorage.setItem('isNextTab', 'yes');
    if((localStorage.getItem('Authority') =='CARE_GIVER' && localStorage.getItem('Authority') !=='RECRUITER' && localStorage.getItem('Authority') !='ADMIN' )){
      this.isCaregiver = true;
      this.isRecruiter = false;
      this.isAdmin = false;
      this.getProfession();
    //  this.getUserProfile();
      this.CaregiverProfileForm();
      this.phoneMandatory();
      this.checkBoxMandatory();
    }    
    else if((localStorage.getItem('Authority') !=='CARE_GIVER' && localStorage.getItem('Authority') =='RECRUITER' && localStorage.getItem('Authority') !='ADMIN' )){
      this.isRecruiter = true;
      this.isCaregiver = false;
      this.isAdmin = false;
   // this.getUserProfile();
    this.RecruiterProfileForm();
    this.phoneMandatory();
    }else if ((localStorage.getItem('Authority') =='ADMIN' && localStorage.getItem('Authority') !='RECRUITER' && localStorage.getItem('Authority') !='CARE_GIVER')){
      this.isRecruiter = false;
      this.isCaregiver = false;
      this.isAdmin = true;
   // this.getUserProfile();
    this.AdminProfileForm();
    this.phoneMandatory();
    }
    this.getUserProfile();
    document.getElementById('profile').style.backgroundColor ='#2777BA';
    document.getElementById('profile').style.color = 'white';
    document.getElementById('password').style.backgroundColor = 'white';
    document.getElementById('password').style.color = '#2777BA';
    this.isProfilOpen = true;
    this.updatePasswordForm();
    this.updatePassword.get('smsToken').disable();
    this.updatePassword.controls['passwordGroup'].get('password').disable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').disable();

  }
  radioButton(event){
  //  console.log(event);
  if(event.source.checked && event.source.value == "email"){
    this.isEmail = true;
  }
  else if(!event.source.checked && event.source.value == "email"){
    this.isEmail = false;
  }
   if(event.source.checked && event.source.value == "sms"){
    this.isMobile = true;
    this.isNoteOpen = true;

  }
  else if(!event.source.checked && event.source.value == "sms"){
    this.isMobile = false;
    this.isNoteOpen = false;

  }
    }
    CaregiverProfileForm() {
      this.updateCaregiverForm = this.formBuilder.group({
       // role: 'CARE_GIVER',
        firstName: ['', [Validators.required, Validators.max(13)]],
        lastName: ['', [Validators.required, Validators.max(13)]],
        username: ['', [Validators.required, this.validationService.checkEmail]],
        otcUserName: ['',[Validators.pattern(this.validationService.otcregex)]],
        phoneNumber: ['', [this.validationService.checkPhoneNumber]],
        otcPhoneNumber: ['',[Validators.pattern(this.validationService.otcregex)]],
        profession:['',[Validators.required]],
        speciality:['',[Validators.required]],
        enableEmailNotif:['email', [Validators.required]],
        enableMobileNotif:['sms', [Validators.required]],
        'validate': ''
      });
      }
      RecruiterProfileForm() {
        this.updateRecruiterForm = this.formBuilder.group({
         // role: 'CARE_GIVER',
         firstName: ['', [Validators.required, Validators.max(13)]],
         lastName: ['', [Validators.required, Validators.max(13)]],
          username: ['', [Validators.required, this.validationService.checkEmail]],
          otcUserName: ['',[Validators.pattern(this.validationService.otcregex)]],
          phoneNumber: ['', [this.validationService.checkPhoneNumber]],
          otcPhoneNumber: ['',[Validators.pattern(this.validationService.otcregex)]],
          agencyName:['', [Validators.required]],
          enableEmailNotif:['email', [Validators.required]],
          enableMobileNotif:['sms', [Validators.required]],
          'validate': ''
        });
        }
        AdminProfileForm() {
          this.updateAdminForm = this.formBuilder.group({
           // role: 'CARE_GIVER',
           firstName: ['', [Validators.required, Validators.max(13)]],
           lastName: ['', [Validators.required, Validators.max(13)]],
            username: ['', [Validators.required, this.validationService.checkEmail]],
            otcUserName: ['',[Validators.pattern(this.validationService.otcregex)]],
            phoneNumber: ['', [this.validationService.checkPhoneNumber]],
            otcPhoneNumber: ['',[Validators.pattern(this.validationService.otcregex)]],
            enableEmailNotif:['email', [Validators.required]],
            enableMobileNotif:['sms', [Validators.required]],
            'validate': ''
          });
          }
          checkBoxMandatory(){
            if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
              const care_PhoneControl = this.updateCaregiverForm.get('enableMobileNotif');
            const care_EmailControl = this.updateCaregiverForm.get('enableEmailNotif');
            this.updateCaregiverForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isEmail){
                this.updateCaregiverForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              care_PhoneControl.updateValueAndValidity();
            }
            )
            this.updateCaregiverForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isNoteOpen){
                this.updateCaregiverForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              care_EmailControl.updateValueAndValidity();
            })
            }
            if(!this.isCaregiver && this.isRecruiter && !this.isAdmin){
              const recPhoneControl = this.updateRecruiterForm.get('enableMobileNotif');
            const recEmailControl = this.updateRecruiterForm.get('enableEmailNotif');
            this.updateRecruiterForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isEmail){
                this.updateRecruiterForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              recPhoneControl.updateValueAndValidity();
            })
            this.updateRecruiterForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isNoteOpen){
                this.updateRecruiterForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              recEmailControl.updateValueAndValidity();
            })
            }
            if(!this.isCaregiver && !this.isRecruiter && this.isAdmin){
   
            const admPhoneControl = this.updateAdminForm.get('enableMobileNotif');
            const admEmailControl = this.updateAdminForm.get('enableMobileNotif');
           
         
            this.updateAdminForm.get('enableMobileNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isEmail){
                this.updateAdminForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              admPhoneControl.updateValueAndValidity();
            })
            this.updateAdminForm.get('enableEmailNotif').valueChanges.pipe(distinctUntilChanged())
            .subscribe(value => {
              if(!value && !this.isNoteOpen){
                this.updateAdminForm.get('enableEmailNotif').setValidators([Validators.required])
              }
              admEmailControl.updateValueAndValidity();
            })
          }
          }
        phoneMandatory(){
          if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
            const phoneControl = this.updateCaregiverForm.get('phoneNumber');
          this.updateCaregiverForm.get('enableMobileNotif').valueChanges
            .subscribe(value => {
              this.isEnableStar = value;
              if(value) {
                this.updateCaregiverForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
              } else {
                this.updateCaregiverForm.get('phoneNumber').setValidators([Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
              }
              phoneControl.updateValueAndValidity();
            }
        );
      //   this.updateCaregiverForm.get('enableEmailNotif').valueChanges
      //   .subscribe(value => {
      //     if(value) {
      //       this.updateCaregiverForm.get('phoneNumber').setValidators([ Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
      //     } else {
      //       this.updateCaregiverForm.get('phoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.phoneregex), this.validationService.checkPhoneNumber])
      //     }
      //     phoneControl.updateValueAndValidity();
      //   }
      //   );
       }
     else if(!this.isCaregiver && !this.isAdmin && this.isRecruiter) {
      const recruiterphoneControl = this.updateRecruiterForm.get('phoneNumber');
        this.updateRecruiterForm.get('enableMobileNotif').valueChanges
            .subscribe(value => {
              this.isEnableStar1 = value;
              if(value) {
                this.updateRecruiterForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
              } else {
                this.updateRecruiterForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
              }
              recruiterphoneControl.updateValueAndValidity();
            }
        );
        // this.updateRecruiterForm.get('enableEmailNotif').valueChanges
        // .subscribe(value => {
        //   if(value) {
        //     this.updateRecruiterForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
        //   } else {
        //     this.updateRecruiterForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
        //   }
        //   recruiterphoneControl.updateValueAndValidity();
        // }
        // );
      }
      else if(!this.isCaregiver && !this.isRecruiter && this.isAdmin){
        const adminphoneControl = this.updateAdminForm.get('phoneNumber');
          this.updateAdminForm.get('enableMobileNotif').valueChanges
              .subscribe(value => {
                this.isEnableStar1 = value;
                if(value) {
                  this.updateAdminForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
                } else {
                  this.updateAdminForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
                }
                adminphoneControl.updateValueAndValidity();
              }
          );
          // this.updateAdminForm.get('enableEmailNotif').valueChanges
          // .subscribe(value => {
          //   if(value) {
          //     this.updateAdminForm.get('phoneNumber').setValidators([this.validationService.checkPhoneNumber])
          //   } else {
          //     this.updateAdminForm.get('phoneNumber').setValidators([Validators.required, this.validationService.checkPhoneNumber])
          //   }
          //   adminphoneControl.updateValueAndValidity();
          // }
          // );
        }
        }
        otcMandatory(){
          if((localStorage.getItem('Authority') =='CARE_GIVER' && localStorage.getItem('Authority') !=='RECRUITER')){
            if(this.isMobileToken){
            const mobileTokenControl = this.updateCaregiverForm.get('otcPhoneNumber');
            this.updateCaregiverForm.get('otcPhoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
          else if(!this.isMobileToken){
            const mobileTokenControl = this.updateCaregiverForm.get('otcPhoneNumber');
            this.updateCaregiverForm.get('otcPhoneNumber').setValidators([Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
           if(this.isEmailToken){
            const emailTokenControl = this.updateCaregiverForm.get('otcUserName');
            this.updateCaregiverForm.get('otcUserName').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
          else if(!this.isEmailToken){
            const emailTokenControl = this.updateCaregiverForm.get('otcUserName');
            this.updateCaregiverForm.get('otcUserName').setValidators([Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
        }
        else if((localStorage.getItem('Authority') !=='CARE_GIVER' && localStorage.getItem('Authority') =='RECRUITER')){
          if(this.isMobileToken){
            const mobileTokenControl = this.updateRecruiterForm.get('otcPhoneNumber');
            this.updateRecruiterForm.get('otcPhoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
          else if(!this.isMobileToken){
            const mobileTokenControl = this.updateRecruiterForm.get('otcPhoneNumber');
            this.updateRecruiterForm.get('otcPhoneNumber').setValidators([Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
           if(this.isEmailToken){
            const emailTokenControl = this.updateRecruiterForm.get('otcUserName');
            this.updateRecruiterForm.get('otcUserName').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
          else if(!this.isEmailToken){
            const emailTokenControl = this.updateRecruiterForm.get('otcUserName');
            this.updateRecruiterForm.get('otcUserName').setValidators([Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
        }
        else if((localStorage.getItem('Authority') !=='CARE_GIVER' && localStorage.getItem('Authority') !='RECRUITER' && localStorage.getItem('Authority') =='ADMIN')){
          if(this.isMobileToken){
            const mobileTokenControl = this.updateAdminForm.get('otcPhoneNumber');
            this.updateAdminForm.get('otcPhoneNumber').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
          else if(!this.isMobileToken){
            const mobileTokenControl = this.updateAdminForm.get('otcPhoneNumber');
            this.updateAdminForm.get('otcPhoneNumber').setValidators([Validators.pattern(this.validationService.otcregex)])
            mobileTokenControl.updateValueAndValidity();
          }
           if(this.isEmailToken){
            const emailTokenControl = this.updateAdminForm.get('otcUserName');
            this.updateAdminForm.get('otcUserName').setValidators([Validators.required, Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
          else if(!this.isEmailToken){
            const emailTokenControl = this.updateAdminForm.get('otcUserName');
            this.updateAdminForm.get('otcUserName').setValidators([Validators.pattern(this.validationService.otcregex)])
            emailTokenControl.updateValueAndValidity();
          }
        }
        }
        
      updatePasswordForm(){
        this.updatePassword = this.formBuilder.group({
          smsToken :new FormControl('', [Validators.required, Validators.pattern(this.validationService.otcregex)]),
          passwordGroup: this.formBuilder.group(
            {
          password:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
          confirmPassword:new FormControl('', [Validators.required, Validators.pattern(this.validationService.passwordregex)]),
            },
            {
              'validators':CustomValidators.checkPassword
            })
        })
      }
      getProfession(){
          return this.userService.getProfessions().subscribe(professionsObj => {
                if (!!professionsObj) {
                  this.professions = professionsObj.professionLookups;
          this.professions.sort(function(a, b) {
            var nameA = a.professionName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.professionName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
                 
                }
        });
      
      }
      populateSpecialties(event) {
        this.updateCaregiverForm.get('speciality').reset();
        this.userService.getSpecialties(event.value.id)
        .subscribe(specialties => {
          this.specialities = specialties;
          this.specialities.sort(function(a, b) {
            var nameA = a.specialtyName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.specialtyName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
        });
      }
      speciality(){
        this.userService.getSpecialties(this.professionId)
        .subscribe(specialties => {
          this.specialities = specialties;
          this.specialities.sort(function(a, b) {
            var nameA = a.specialtyName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.specialtyName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
          const toSelectSpecialty = this.specialities.find(c => c.id == this.specialityId);
    this.updateCaregiverForm.controls['speciality'].setValue(toSelectSpecialty);
        });
      }
      openProfile(){
        window.scrollTo( 0, 0 );
        document.getElementsByTagName('html')[0].scrollTop=0;
        this.otcMessageEmail = false;
        this.otcMessageMobile = false;
        this.updatePassword.controls['smsToken'].setValue('');
        this.updatePassword.controls['passwordGroup'].get('password').setValue('');
        this.updatePassword.controls['passwordGroup'].get('confirmPassword').setValue('');   
    document.getElementById('profile').style.backgroundColor ='#2777BA';
    document.getElementById('profile').style.color = 'white';
    document.getElementById('password').style.backgroundColor = 'white';
    document.getElementById('password').style.color = '#2777BA';
        this.isProfilOpen = true;
        this.isChangePwdOpen=false;
        this.getUserProfile();
      }
      viewPhoto(){
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        this.isOptionsOpen = false;
        this.dialog.open(this.imageView, dialogConfig);
      }
      getErrorEmail() {
        return this.updateRecruiterForm.get('username').hasError('required') ? 'Email is required.' :
          this.updateRecruiterForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorPhone() {
        return this.updateRecruiterForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
          this.updateRecruiterForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorEmail1() {
        return this.updateCaregiverForm.get('username').hasError('required') ? 'Email is required.' :
       // this.updateCaregiverForm.get('username').hasError('whitespace') ? 'noWhiteSpace' :
          this.updateCaregiverForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorEmail2() {
        return this.editEmailForm.get('username').hasError('required') ? 'Email is required.' :
          this.editEmailForm.get('username').hasError('emailrequire') ? 'Not a valid email address.' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorPhone1() {
        return this.updateCaregiverForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
          this.updateCaregiverForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorPhone2() {
        return this.editPhoneForm.get('phoneNumber').hasError('required') ? 'Phone number is required.' :
          this.editPhoneForm.get('phoneNumber').hasError('phoneRequirements') ? 'Not a valid phone number (Ex. 1111111111).' : '';
           // this.caregiverForm.get('email').hasError('alreadyInUse') ? 'This email address is already in use' : '';
      }
      getErrorOtc(){
        return this.updatePassword.get('smsToken').hasError('required') ? 'Field is required.' :
        this.updatePassword.get('smsToken').hasError('pattern') ?
        'Not a valid OTC, OTC should be 4 digits.' : '';
      }
      getErrorOtc1(){
        return this.updateCaregiverForm.get('otcUserName').hasError('required') ? 'Verification code is required.' :
        this.updateCaregiverForm.get('otcUserName').hasError('pattern') ?
        'Not a valid verification code, It should be 4 digits.' : '';
      }
      getErrorOtc2(){
        return this.updateCaregiverForm.get('otcPhoneNumber').hasError('required') ? 'Verification code is required.' :
        this.updateCaregiverForm.get('otcPhoneNumber').hasError('pattern') ?
        'Not a valid verification code, It should be 4 digits.' : '';
      }
      getErrorOtc3(){
        return this.updateRecruiterForm.get('otcUserName').hasError('required') ? 'Verification code is required.' :
        this.updateRecruiterForm.get('otcUserName').hasError('pattern') ?
        'Not a valid verification code, It should be 4 digits.' : '';
      }
      getErrorOtc4(){
        return this.updateRecruiterForm.get('otcPhoneNumber').hasError('required') ? 'Verification code is required.' :
        this.updateRecruiterForm.get('otcPhoneNumber').hasError('pattern') ?
        'Not a valid verification code, It should be 4 digits.' : '';
      }
      onSelectFile(event) { /** selecting image */
        if(event.target.files && event.target.files[0]){
          const file = event.target.files[0];
          let formData:FormData = new FormData();
          formData.append('ImportFile', file);
          this.userService.uploadProfilePicture(formData).subscribe(
            (res) => {
              if(res){
              this.getUserProfile();
              this.authenticationService.raiseProfileEvent();
              }
             /// console.log(res);
            },
            (err) => {
              if(err){
                this.getUserProfile();
                }
            //  console.log(err);
            })
          
        }
          
    
    
      }
getUserProfile(){
  this.userService.EditUserProfile()
  .subscribe((res)=>{
    if(res.body.hasImage == "NO"){
      this.isSelectImage = false;
    }
    else if(res.body.hasImage == "YES"){
      this.isSelectImage = true;
      this.userImage ='data:image/png;base64,' + res.body.userImage;
    }
   this.fullName = res.body.fullName;
   this.userName = res.body.email;
   this.phoneNumber = res.body.phoneNumber;
   if(this.isCaregiver && !this.isRecruiter){
   this.professionId = res.body.professionId;
   this.specialityId = res.body.specialtyId;
   this.speciality();
   const toSelectProfession = this.professions.find(c => c.id == res.body.professionId);
    this.updateCaregiverForm.controls['profession'].setValue(toSelectProfession);
    this.updateCaregiverForm.controls['firstName'].setValue(res.body.firstName);
    this.updateCaregiverForm.controls['lastName'].setValue(res.body.lastName);
    this.updateCaregiverForm.controls['username'].setValue(res.body.email);
    this.updateCaregiverForm.controls['phoneNumber'].setValue(res.body.phoneNumber);
    
   }
   else if(this.isRecruiter && !this.isCaregiver){
     this.updateRecruiterForm.controls['agencyName'].setValue(res.body.agencyName);
     this.updateRecruiterForm.controls['firstName'].setValue(res.body.firstName);
     this.updateRecruiterForm.controls['lastName'].setValue(res.body.lastName);
    this.updateRecruiterForm.controls['username'].setValue(res.body.email);
    this.updateRecruiterForm.controls['phoneNumber'].setValue(res.body.phoneNumber);
    
   }
   else if(!this.isRecruiter && !this.isCaregiver && this.isAdmin){
    this.updateAdminForm.controls['firstName'].setValue(res.body.firstName);
    this.updateAdminForm.controls['lastName'].setValue(res.body.lastName);
   this.updateAdminForm.controls['username'].setValue(res.body.email);
   this.updateAdminForm.controls['phoneNumber'].setValue(res.body.phoneNumber);
   
  }
   this.loginToken = res.body.userToken;
   this.userId = res.body.userId
     
    if(res.body.enableEmailNotif === "YES"){
      this.Email = 'YES';
      this.isEmail = true;
      if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
        this.updateCaregiverForm.controls['enableEmailNotif'].setValue(true);
      }
      else if(this.isRecruiter && !this.isCaregiver && !this.isAdmin){
      this.updateRecruiterForm.controls['enableEmailNotif'].setValue(true);
      }
      else if(!this.isRecruiter && !this.isCaregiver && this.isAdmin){
        this.updateAdminForm.controls['enableEmailNotif'].setValue(true);
        }
    }
    else {
      this.isEmail = false;
    }
     if(res.body.enableMobileNotif === "YES"){
      this.isMobile = true;
      this.isNoteOpen = true;
           if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
      this.updateCaregiverForm.controls['enableMobileNotif'].setValue(true);
    }
    if(this.isRecruiter && !this.isCaregiver && !this.isAdmin){
      this.updateRecruiterForm.controls['enableMobileNotif'].setValue(true);
    }
    else if(!this.isRecruiter && !this.isCaregiver && this.isAdmin){
      this.updateAdminForm.controls['enableMobileNotif'].setValue(true);
      }
    }
    else {
      this.isMobile = false;
      this.isNoteOpen = false;
    }
    
  },
  (err)=>{
  }
  )
}

onProfileUpdate(post){ /** update profile details */
  this.isloading = true;
  const inputObj:any={};
  if(post.enableEmailNotif == true){
    inputObj.enableEmailNotif = "YES"
  }
  else{
    inputObj.enableEmailNotif = "NO"
  }
  if(post.enableMobileNotif == true){
    inputObj.enableMobileNotif = "YES"
 }
 else{
  inputObj.enableMobileNotif = "NO"
 }   
 if(inputObj.enableEmailNotif !== "YES" && inputObj.enableMobileNotif !== "YES"){
  this.dialog.open(this.selectNotipre);
  this.isloading = false;
} else if ((this.updateAdminForm.valid || this.updateCaregiverForm.valid || this.updateRecruiterForm.valid) && (inputObj.enableEmailNotif == "YES" || inputObj.enableMobileNotif == "YES")){
   inputObj.fullName = post.fullName;
   if(this.isCaregiver){
   inputObj.profession = post.profession.id;
   inputObj.speciality = post.speciality.id;
  }
  else if(this.isRecruiter){
    inputObj.agencyName = post.agencyName.trim();
  }
  if(post.phoneNumber !=null){
    inputObj.phoneNumber = post.phoneNumber.trim();
    this.updatePhoneNumber = post.phoneNumber.trim();
  }
   
   inputObj.username = post.username.trim();
  // var fullname = post.fullName.split(' ');
   inputObj.firstName = post.firstName.trim();
   inputObj.lastName = post.lastName.trim();
   inputObj.userToken = this.loginToken;
   inputObj.userId = this.userId;
  this.userService.updateUserProfile(inputObj).subscribe((res:any)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isloading = false;
    if(res.responseCode == 200 && res.responseMessage == "Profile Updated" ){
      this.alertService.success('Success! Your details have been updated', this.options);
     //this.getUserProfile();
     sessionStorage.setItem("updateSuccessMessage", "Yes");
     this.authenticationService.raiseProfileEvent();
     if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
      this.router.navigateByUrl('/caregiverhome');
     this.router.navigate(['/caregiverhome'], { relativeTo:this.route} )
     }else if(!this.isCaregiver && this.isRecruiter && !this.isAdmin){
      this.router.navigateByUrl('/recruiteraccess-documents');
     this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route} )
     }else if(!this.isCaregiver && !this.isRecruiter && this.isAdmin){
      this.router.navigateByUrl('/admin-main-page');
     this.router.navigate(['/admin-main-page'], { relativeTo:this.route} )
     }
     
    }
    else if(res.responseMessage == "TOKEN_SENT_TO_MOBILE;"){
      this.alertService.success('Verification code sent to updated phone Number for validation', this.options);
      this.isMobileToken = true;
      this.otcMandatory();
    }
    else if(res.responseMessage == "TOKEN_SENT_TO_EMAIL;"){
      this.alertService.success('Verification code sent to updated Email/username for validation', this.options);
      this.isEmailToken = true
      this.otcMandatory();
    }
    else if(res.responseMessage == "TOKEN_SENT_TO_EMAIL;TOKEN_SENT_TO_MOBILE;"){
      this.alertService.success('Verification code sent to updated Email/username and phone Number for validation', this.options);
      this.isEmailToken = true;
      this.isMobileToken = true;
      this.otcMandatory();
    }
    else if(res.responseMessage == "MOBILE_NUMBER_ALREADY_IN_USE;"){
      this.alertService.error('Phone Number already in use, Please try with another one', this.options);
    }
    else if(res.responseMessage == "USERNAME_ALREADY_IN_USE;"){
      this.alertService.error('Email/Username already in use, Please try with another one', this.options);
    }
    else if(res.responseMessage == "USERNAME_ALREADY_IN_USE;MOBILE_NUMBER_ALREADY_IN_USE;"){
      this.alertService.error('Email/Username and Phone Number are already in use, Please try with another details', this.options);
    }
    else if(res.responseMessage == "USERNAME_ALREADY_IN_USE;TOKEN_SENT_TO_MOBILE;"){
      this.isMobileToken = true;
      this.otcMandatory();
      this.alertService.error('Email/Username already in use, Please try with another one', this.options);
      this.alertService.success('Verification code sent to updated phone Number for validation', this.options)
    }
    else if(res.responseMessage == "TOKEN_SENT_TO_EMAIL;MOBILE_NUMBER_ALREADY_IN_USE;"){
      this.isEmailToken = true;
      this.otcMandatory();
      this.alertService.success('Verification code sent to updated Email/username for validation', this.options);
      this.alertService.error('Phone Number already in use, Please try with another one', this.options);
    }
    else if(res.status == 500){
      this.alertService.error('Sorry! something went wrong', this.options);
      this.isloading = false;
    }
  
  })
  } 
}
  onValidateAndUpdate(post){
    const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
    this.isloading = true;
    const inputObj:any={};
    inputObj.validateToken = "YES"
    if(post.enableEmailNotif == true){
      inputObj.enableEmailNotif = "YES"
    }
    else{
      inputObj.enableEmailNotif = "NO"
    }
    if(post.enableMobileNotif == true){
      inputObj.enableMobileNotif = "YES"
   }
   else{
    inputObj.enableMobileNotif = "NO"
   }   
   if(inputObj.enableEmailNotif !== "YES" && inputObj.enableMobileNotif !== "YES"){
    this.dialog.open(this.selectNotipre);
    this.isloading = false;
  } else if ((this.updateAdminForm.valid || this.updateCaregiverForm.valid || this.updateRecruiterForm.valid) && (inputObj.enableEmailNotif == "YES" || inputObj.enableMobileNotif == "YES")){
   
     inputObj.fullName = post.fullName;
     if(this.isCaregiver){
     inputObj.profession = post.profession.id;
     inputObj.speciality = post.speciality.id;
    }
    else{
      inputObj.agencyName = post.agencyName.trim();
    }
    inputObj.changePhoneToken = post.otcPhoneNumber;
    inputObj.changeUsernameToken = post.otcUserName;
     inputObj.phoneNumber = post.phoneNumber.trim();
     this.updatePhoneNumber = post.phoneNumber.trim();
     inputObj.username = post.username.trim();
    // var fullname = post.fullName.split(' ');
     inputObj.firstName = post.firstName.trim();
     inputObj.lastName = post.lastName.trim();
     inputObj.userToken = this.loginToken;
     inputObj.userId = this.userId;
    this.userService.updateUserProfile(inputObj).subscribe((res:any)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      this.isloading = false;
      if(res.responseCode == 200 && res.responseMessage == null && !this.isEmailToken){
        this.alertService.success('Success! Your details have been updated', this.options);
        this.clearOtc();
        this.isMobileToken = false;
        this.isEmailToken = false;
        this.otcMandatory();
       // this.getUserProfile()
       sessionStorage.setItem("updateSuccessMessage", "Yes");
       this.authenticationService.raiseProfileEvent();
       if(this.isCaregiver && !this.isRecruiter && !this.isAdmin){
        this.router.navigateByUrl('/caregiverhome');
       this.router.navigate(['/caregiverhome'], { relativeTo:this.route} )
       }else if(!this.isCaregiver && this.isRecruiter && !this.isAdmin){
        this.router.navigateByUrl('/recruiteraccess-documents');
       this.router.navigate(['/recruiteraccess-documents'], { relativeTo:this.route} )
       }else if(!this.isCaregiver && !this.isRecruiter && this.isAdmin){
        this.router.navigateByUrl('/admin-main-page');
       this.router.navigate(['/admin-main-page'], { relativeTo:this.route} )
       }
         }
         else if(res.responseCode == 200 && res.responseMessage == "Profile_Email Updated" && this.isEmailToken){
          this.alertService.success('Success! Your details have been updated', this.options);
          this.isMobileToken = false;
          this.isEmailToken = false;
          this.dialog.open(this.redirectToLogin, dialogConfig);
           }
      else if(res.responseMessage == "NEW_PHONE_NUMBER_AND_TOKEN_NOT_MATCHED;"){
        this.isEmailToken = false;
        this.alertService.error('Entered Verification code is not valid', this.options)
      }
      else if(res.responseMessage == "NEW_EMAIL_ID_AND_TOKEN_NOT_MATCHED;"){
        this.isMobileToken = false;
        this.alertService.error('Entered Verification code is not valid', this.options)
      }
      else if(res.responseMessage == "NEW_EMAIL_ID_AND_TOKEN_NOT_MATCHED;NEW_PHONE_NUMBER_AND_TOKEN_NOT_MATCHED;"){
        this.alertService.error('Entered Verification codes are not valid', this.options)
      }
      else if(res.status == 500){
        this.alertService.error('Sorry! something went wrong', this.options);
      }
    
    })
  }
}
  redirectsToLogin(){
    this.router.navigateByUrl('/login');
    this.router.navigate(['/login'], { relativeTo:this.route});
    this.authenticationService.logout();
    this.userService.logout();
  }
  openChangePwd(){
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.updatePassword.get('smsToken').disable();
    this.updatePassword.controls['passwordGroup'].get('password').disable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').disable();
    document.getElementById('password').style.backgroundColor ='#2777BA';
    document.getElementById('password').style.color = 'white';
    document.getElementById('profile').style.backgroundColor = 'white';
    document.getElementById('profile').style.color = '#2777BA';
    this.isChangePwdOpen=true;
    this.isProfilOpen = false;
  }
  generateOtc(){
    if(this.otcMessageEmail || this.otcMessageMobile){
      this.isResendLoading = true;
    }
    this.authenticationService.generateChangePwdToken()
    .subscribe((res:any)=>{
      this.isResendLoading = false;
      if(res.responseMessage == "NOTIF_SENT_TO_EMAIL" && res.responseCode == 200){
        this.otcMessageEmail=true;
        this.otcMessageMobile = false
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE" && res.responseCode == 200){
        this.otcMessageEmail= false;
        this.otcMessageMobile = true
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE,NOTIF_SENT_TO_EMAIL" && res.responseCode == 200){
        this.otcMessageEmail= true;
        this.otcMessageMobile = true
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "INVALID_NOTIF_SETTINGS"){
        this.otcMessageEmail = false;
        this.otcMessageMobile = false;
      }
    })
  }
  generateResendOtc(){
    const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
    if(this.otcMessageEmail || this.otcMessageMobile){
      this.isResendLoading = true;
    }
    this.authenticationService.generateChangePwdToken()
    .subscribe((res:any)=>{
      this.isResendLoading = false;
      if(res.responseMessage == "NOTIF_SENT_TO_EMAIL" && res.responseCode == 200){
        this.dialog.open(this.reSentOtc, dialogConfig);
        this.text ="Verification code has been sent to "+this.userName+" .Please check your email and change your password."
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE" && res.responseCode == 200){
        this.dialog.open(this.reSentOtc, dialogConfig);
        this.text = "Verification code has been sent to " +this.phoneNumber+" . Please check your text message and change your password."
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "NOTIF_SENT_TO_MOBILE,NOTIF_SENT_TO_EMAIL" && res.responseCode == 200){
        this.dialog.open(this.reSentOtc, dialogConfig);
       this.text= "Verification code has been sent to "+this.userName +" and "+this.phoneNumber+ " .Please check your email/text message and change your password.";
        this.updatePassword.get('smsToken').enable();
    this.updatePassword.controls['passwordGroup'].get('password').enable();
    this.updatePassword.controls['passwordGroup'].get('confirmPassword').enable();
      }
      else if(res.responseMessage == "INVALID_NOTIF_SETTINGS"){
        this.otcMessageEmail = false;
        this.otcMessageMobile = false;
      }
    })
  }
onPasswordUpdate(post){ /** update user password */
  this.isPwdloading = true;
  const inputObj:any ={};
  inputObj.smsToken = post.smsToken;
  inputObj.password = post.passwordGroup.password;
this.authenticationService.changePassword(inputObj).
pipe(mergeMap(result=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.isPwdloading = false;
  //console.log('passwordResp', result);
  if(result.responseCode == 200){
    this.isPwdloading = false;
    this.alertService.success(' Password changed successfully', this.options)
    this.authenticationService.logout();
    this.userService.logout();
    this.router.navigateByUrl('/login');
              this.router.navigate(['/login'], { relativeTo:this.route});
  }
  return result;
}
  )).subscribe((res)=>{
  },
(err)=>{
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.isPwdloading = false;
  if(err.error.message == 'INVALID_CHANGE_PASSWORD_TOKEN' && err.error.status === 500){
    this.alertService.error('Entered verification code is invalid', this.options);
  }
  else{
    this.alertService.error(err.error.message, this.options);
  }
 // console.log('error', err);
}
)
}
clearOtc(){
  if(localStorage.getItem('Authority') == "RECRUITER" && this.isEmailToken){
  this.updateRecruiterForm.get('otcUserName').reset();
  }
 else if(localStorage.getItem('Authority') == "RECRUITER" && this.isMobileToken){
    this.updateRecruiterForm.get('otcPhoneNumber').reset();
    }
    else if(localStorage.getItem('Authority') == "CARE_GIVER" && this.isMobileToken){
      this.updateCaregiverForm.get('otcPhoneNumber').reset();
    }
    else if(localStorage.getItem('Authority') == "CARE_GIVER" && this.isEmailToken){
      this.updateCaregiverForm.get('otcUserName').reset();
      }
}
closeChangePwd(){
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  this.formDirective.resetForm();
  this.otcMessageEmail = false;
  this.otcMessageMobile = false;
  this.updatePassword.get('smsToken').disable();
  this.updatePassword.controls['passwordGroup'].get('password').disable();
  this.updatePassword.controls['passwordGroup'].get('confirmPassword').disable();
  this.router.navigateByUrl('/caregiverhome');
this.router.navigate(['/caregiverhome'], { relativeTo:this.route});
 /* this.updatePassword.controls['smsToken'].setValue('');
  this.updatePassword.controls['passwordGroup'].get('password').setValue('');
  this.updatePassword.controls['passwordGroup'].get('confirmPassword').setValue('');      
document.getElementById('password').style.backgroundColor ='white';
document.getElementById('password').style.color = 'black';
document.getElementById('profile').style.backgroundColor = 'white';
document.getElementById('profile').style.color = 'black'; */
}
closeAll(){
  //this.formDirective.resetForm();
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
this.isEmailToken =false;
this.isMobileToken = false;
//this.getUserProfile();
this.otcMandatory();
this.router.navigateByUrl('/caregiverhome');
this.router.navigate(['/caregiverhome'], { relativeTo:this.route});
}

}
